* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
}

body {
	height: 100vh;
	width: 100vw;
	margin: 0;

	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:root {
	--newsbarTransition-duration: 0s;
}

.source-titleBackground {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0;
	z-index: 1;
}

.source-title {
	display: flex;
	background-color: #0fadd3;
	flex: 1;
	text-align: center;
	height: 100%;
	width: 100%;
	top: 100%;
	align-items: center;
	justify-content: center;
	position: relative;
	color: #fff;
	text-transform: uppercase;
	box-sizing: border-box;
	font-size: 40px;
	padding-right: 7px;
	white-space: nowrap;
}

.source-content {
	display: flex;
	background-color: white;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: relative;
	height: 100%;
	top: 100%;
	color: black;
	text-transform: uppercase;
	margin-left: 7px;
	box-sizing: border-box;
	font-size: 40px;
	white-space: nowrap;
	animation: slide linear var(--newsbarTransition-duration);
	animation-delay: 2s;
}

@keyframes slide {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-100%, 0, 0);
	}
}

.source-content div {
	display: flex;
	height: 964px;
	justify-content: center;
	align-items: center;
}

.source-content span {
	text-align: center;
	margin: 0 !important;
}

span.separator {
	font-size: 20px;
	width: 24px;
	color: #0fadd3;
}

/*Fade*/

:root {
	--animation-duration: 0s;
}

.fade {
	animation: fadeIn ease-out 0.5s;
}
/* var(--animation-duration) */
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-out {
	animation: fadeOut ease-out;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.slide-up {
	animation: slideUp ease-in-out var(--animation-duration);
}

@keyframes slideUp {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}

.slide-down {
	animation: slideDown ease-in-out var(--animation-duration);
}

@keyframes slideDown {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0%);
	}
}

.slide-left {
	animation: slideLeft ease-in-out var(--animation-duration);
}

@keyframes slideLeft {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0%);
	}
}

.slide-right {
	animation: slideRight ease-in-out var(--animation-duration);
}

@keyframes slideRight {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0%);
	}
}
